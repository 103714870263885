/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import ML from '../images/mentions_legales.pdf'
import CGU from '../images/cgu.pdf'
import Privacy from '../images/privacy_policy.pdf'

import Header from "./header"
import "./w3.css"
import "./layout.css"


const whiteLink = {
  color: `white`,
  fontWeight: `bold`,
  textDecoration: `none`,
  margin: `0 10px 0 10px`
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
          style={{
            margin: `0`,
            textAlign: `center`
          }}
        >
        <main>{children}</main>
        <footer style={{
            backgroundImage: `linear-gradient(to bottom, #ff795c, #ff5c5c)`,
            padding: `20px`
          }}>
          <a href={CGU} target="_blank" rel="noopener noreferrer" style={whiteLink}>
            CGU
          </a>
          <Link to="/contact" style={whiteLink}>
            Contact
          </Link>
          <a href={ML} target="_blank" rel="noopener noreferrer" style={whiteLink}>
            Mentions légales
          </a>
          <a href={Privacy} target="_blank" rel="noopener noreferrer" style={whiteLink}>
            Politique de confidentialité
          </a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
