import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import HeaderSVG from './header.svg';

const Header = ({ siteTitle }) => (
  <header
    style={{
      display: `block`,
      position: `relative`,
    }}
    >
    <Link to="/">
      <img src={HeaderSVG} style={{marginBottom: `0`, minHeight: `150px`, width: `100%`}} className="w3-image" alt="Otoschool logo"/>
    </Link>
    <div className="navigation w3-bar">
      <a className="w3-bar-item w3-mobile w3-right whiteLink" href="https://app.otoschool.fr/">
        CONNEXION
      </a>
      <Link className="w3-bar-item w3-mobile w3-right whiteLink" to="/contact">
        CONTACT
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
